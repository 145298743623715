import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { AiOutlineMail, AiOutlinePhone, AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai"
import SEO from "../components/seo"
import styled from 'styled-components'

const StyleSheet = styled.div`
  margin-top: 20px;
  color: #666;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

const Email = styled.a`
  margin-left: 8px;
  text-decoration: none;
  color: #666;
`

const Phone = styled.a`
  margin-left: 8px;
  text-decoration: none;
  color: #666;
`

const Facebook = styled.a`
  margin-left: 8px;
  text-decoration: none;
  color: #666;
`

const Instagram = styled.a`
  margin-left: 8px;
  text-decoration: none;
  color: #666;
`

const ContactPage = ({ data }) => {
  return (
    <div>
      <Layout active={'contact'}>
        <SEO title="Contact" />
        <StyleSheet>
          <Wrapper>
            <AiOutlineMail color="darkgoldenrod" />
            <Email href={`mailto:${data.file.childMarkdownRemark.frontmatter.email}`}>{data.file.childMarkdownRemark.frontmatter.email}</Email>
          </Wrapper>
          <Wrapper>
            <AiOutlinePhone color="darkgoldenrod" />
            <Phone href={`tel:${data.file.childMarkdownRemark.frontmatter.telephone}`}>{data.file.childMarkdownRemark.frontmatter.telephone}</Phone>
          </Wrapper>
          <Wrapper>
            <AiOutlineFacebook color="darkgoldenrod" />
            <Facebook href={data.file.childMarkdownRemark.frontmatter.facebook}>{data.file.childMarkdownRemark.frontmatter.facebook}</Facebook>
          </Wrapper>
          <Wrapper>
            <AiOutlineInstagram color="darkgoldenrod" />
            <Instagram href={data.file.childMarkdownRemark.frontmatter.instagram}>{data.file.childMarkdownRemark.frontmatter.instagram}</Instagram>
          </Wrapper>
        </StyleSheet>
      </Layout>
    </div>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    file(name: {eq: "contact"}) {
      childMarkdownRemark {
        frontmatter {
          title
          email
          telephone
          facebook
          instagram
        }
      }
    }
  }
`
